<template>
  <b-overlay
      :show="[FORM_STATUS.INITIALIZING, FORM_STATUS.LOADING].includes(formStatus)"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    <b-card title="Редактирование категории">
      <div class="category-settings-wrapper">
        <validation-observer ref="categorySettingsValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Название *"
                  label-for="category-settings-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Название"
                    rules="required"
                  >
                    <b-form-input
                      id="category-settings-name"
                      v-model="formData.name"
                      :state="errors.length > 0 ? false : null"
                      name="category-settings-name"
                      placeholder="Бургеры"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Порядковый номер"
                  label-for="category-settings-position"
                >
                  <b-form-input
                    id="category-settings-position"
                    type="number"
                    v-model="formData.position"
                    name="category-settings-position"
                    placeholder="1"
                  />
                  <b-form-text>Укажите номер, который задаст порядок отображения категории в электронном меню</b-form-text>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="submitFormData"
                >
                  Сохранить
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BButton, BRow, BCol, BFormGroup, BFormText, BFormInput, BForm, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'
import { getApiErrMsg } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const FORM_STATUS = {
  INITIAL: 1,
  INITIALIZING: 2,
  LOADING: 3,
  SUCCESS: 4,
  ERROR: 5,
}

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormText,
    BFormInput,
    BForm,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      integer,
      FORM_STATUS,
      formStatus: FORM_STATUS.INITIAL,
      formData: {
        name: '',
        position: null,
      }
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  methods: {
    async fetchCategory() {
      this.formStatus = FORM_STATUS.INITIALIZING

      try {
        const res = await useJwt.getCategory(this.id)
        
        this.initFormData(res?.data)

        this.formStatus = FORM_STATUS.INITIAL
      } catch(err) {
        this.formStatus = FORM_STATUS.INITIAL
      }
    },
    initFormData(data) {
      if (!data) {
        return
      }

      for (const field in this.formData) {
        this.formData[field] = data[field]
      }
    },
    getFormData() {
      const data = {}
      const fields = {
        name: 'name',
        position: 'position'
      }

      for (const field in fields) {
        let fieldValue = this.formData[fields[field]]

        if (field === 'position' && isNaN(parseInt(fieldValue))) {
          fieldValue = 999
        }

        data[field] = fieldValue
      }

      return data
    },
    async submitFormData() {
      const validationRes = await this.$refs.categorySettingsValidation.validate()

      if (!validationRes) {
        return
      }

      this.formStatus = FORM_STATUS.LOADING

      try {
        await useJwt.updateCategory(this.id, this.getFormData())

        this.formStatus = FORM_STATUS.INITIAL

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Данные категории обновлены.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        this.$router.push({ name: 'categories' })
      } catch(err) {
        this.formStatus = FORM_STATUS.INITIAL

        const msg = getApiErrMsg(err, 'Не удалось обновить данные категории, попробуйте еще раз.')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    this.fetchCategory()
  }
}
</script>

<style>
.category-settings-wrapper {
  max-width: 500px;
}
</style>
